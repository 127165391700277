
import rawSchema from '@client/schema';

export const APP_NAME = 'Alexandria Suite';

// Default page size on the homepages.
export const PAGE_SIZE = 10;
// Default page size for content picker results.
export const CONTENT_PICKER_PAGE_SIZE = 20;

// Width of the inside of our drawers, necessary for sizing things like field
// widths and image previews.
export const DRAWER_INNER_WIDTH = 460;
// Width of forms in modal windows.
export const MODAL_WIDTH = 648;

// Angelou Toaster zIndex
export const ERROR_TOAST_ZINDEX = 20;

// Maximum dimensions for image cropping, used by the FocalPoint input.
// Max width is determined by the drawer width.
export const MAX_IMG_WIDTH = DRAWER_INNER_WIDTH;
// Max height was chosen to display the image previews in a pleasing aspect ratio,
// without taking up too much vertical space.
export const MAX_IMG_HEIGHT = 300;

// Amount of characters to 'cut off' from grade band labels, when comparing them as integers
// e.g. 'GRADE_2' -> '2'
export const GRADE_BAND_PREFIX_LENGTH = 6;

// Fast look-up for numberical value of a gradeBand enum.
// Multi-grade bands get handled differently depending on context.
// See client/utils/fields.js for examples
export const NUMERICAL_GRADE_BANDS = {
  GRADE_1: 1,
  GRADE_2: 2,
  GRADE_3: 3,
  GRADE_4: 4,
  GRADE_5: 5,
  GRADE_6: 6,
  GRADE_7: 7,
  GRADE_8: 8,
  GRADE_9: 9,
  GRADE_10: 10,
  GRADE_11: 11,
  GRADE_12: 12
};

// Target lexile levels per grade band.
// Taken from https://github.com/newsela/newsela/blob/master/newsela/articles/models/article.py#L924
// Displayed to end users at https://support.newsela.com/article/grade-to-lexile-conversion/
export const TARGET_LEXILE = {
  // Grade bands.
  GRADE_LOWER_ELEMENTARY: [-400, 620], // Grades K–3.
  GRADE_UPPER_ELEMENTARY: [621, 920], // Grades 4–5.
  GRADE_LOWER_AND_UPPER_ELEMENTARY: [-400, 920], // Grades K–5.
  GRADE_MIDDLE_SCHOOL: [921, 1180], // Grades 6–8.
  GRADE_HIGH_SCHOOL: [1181, 1650], // Grades 9–12.
  // Specific grades.
  GRADE_K: [-400, 0],
  GRADE_1: [1, 300],
  GRADE_2: [301, 500],
  GRADE_3: [501, 620],
  GRADE_4: [621, 780],
  GRADE_5: [781, 920],
  GRADE_6: [921, 1010],
  GRADE_7: [1011, 1109],
  GRADE_8: [1110, 1180],
  GRADE_9: [1181, 1260],
  GRADE_10: [1181, 1260],
  GRADE_11: [1261, 1650],
  GRADE_12: [1261, 1650]
};

// Target word counts per grade band.
// Taken from https://github.com/newsela/newsela/blob/master/newsela/articles/models/article.py#L1035
export const TARGET_WORD_COUNT = {
  // Grade bands.
  GRADE_LOWER_ELEMENTARY: [10, 400], // Grades K–3.
  GRADE_UPPER_ELEMENTARY: [500, 650], // Grades 4–5.
  GRADE_LOWER_AND_UPPER_ELEMENTARY: [10, 650], // Grades K–5.
  GRADE_MIDDLE_SCHOOL: [650, 900], // Grades 6–8.
  GRADE_HIGH_SCHOOL: [850, 1500], // Grades 9–12.
  // Specific grades.
  GRADE_K: [10, 100],
  GRADE_1: [100, 350],
  GRADE_2: [200, 350],
  GRADE_3: [200, 400],
  GRADE_4: [500, 600],
  GRADE_5: [600, 650],
  GRADE_6: [650, 750],
  GRADE_7: [750, 850],
  GRADE_8: [800, 900],
  GRADE_9: [850, 950],
  GRADE_10: [850, 950],
  GRADE_11: [850, 1500],
  GRADE_12: [850, 1500]
};

// Language constants.
export const LANG_EN = 'LANG_EN';
export const LANG_ES = 'LANG_ES';
export const SUPPORTED_LANGUAGES = [LANG_EN, LANG_ES];

// Maps language enums to human-readable string values.
export const LANGUAGE_ENUMS_TO_STRING = {
  LANG_EN: 'English',
  LANG_ES: 'Spanish'
};

// For leveled content, we frequently default them to english and grade 12.
export const DEFAULT_LANG = 'LANG_EN';
export const DEFAULT_GRADE = 'GRADE_12';

// Breadcrumb constants.
export const BREADCRUMB_ELLIPSE = '...';
export const BREADCRUMB_DIVIDER = '/';

// Drawer and Outline widths, as well as Bundles app preview.
export const DRAWER_MIN_WIDTH = 500;
export const OUTLINE_MIN_WIDTH = 400;
export const BUNDLE_PREVIEW_MIN_WIDTH = 500;

// AppHeader and AppSubheader dimensions, used in both the theme and directly in styles.
// The height of AppHeader and AppSubheader are the same.
export const APP_HEADER_HEIGHT = 68;
// Left and right margin for the AppSubheader, Drawer, and Outline.
export const APP_SUBHEADER_MARGIN = 20;

// Input constants.
// Debounce time for text inputs, prevents lag when users are typing.
export const TEXT_DEBOUNCE_TIME = 300;

// Yes/No constants for display.
export const CHECK_MARK = '✓';
export const X_MARK = '✗';

// Tag constants
export const CERTICA_TAG_TYPES = ['CERTICA_TOPIC', 'CERTICA_CONCEPT'];
export const METADATA_SOURCED_TAG_TYPES = CERTICA_TAG_TYPES.concat('READING_SKILLS');
export const NON_EDITABLE_TAG_TYPES = ['CERTICA_TOPIC', 'CERTICA_CONCEPT', 'CONTENT_SETTINGS_TOPIC', 'READING_SKILLS'];

// Maximum number of characters to term search before triggering regex search
export const REGEX_SEARH_MIN = 3;

// Power Word Tier
export const POWER_WORD_TIER = 2;

// Form validation.
export const VALIDATION_ERROR = 'error';
export const VALIDATION_WARNING = 'warning';
// Variants follow the MineralUI guidelines. Errors are called 'danger',
// and warnings are called 'warning'.
export const VARIANT_ERROR = 'danger';
export const VARIANT_WARNING = 'warning';

// Drawer types.
export const NON_PUBLISHABLE = 'Non publishable';
export const PUBLISHABLE = 'Publishable';
export const POWER_WORDS = 'Power words';
export const SINGLE_FIELD = 'Single field';

// Max Z-index value (other values like zIndex_100 come from Mineral UI theme)
export const Z_INDEX_9999_MAX = 9999;

// Tag type abbreviations and colors
export const TAG_CONFIG = {
  CERTICA_CONCEPT: {
    color: 'coreBlue',
    content: 'C'
  },
  CERTICA_TOPIC: {
    color: 'coreBlue',
    content: 'T'
  },
  DEPTH_OF_KNOWLEDGE: {
    color: 'coreBlue',
    content: 'DOK'
  },
  INSTRUCTIONAL_STRATEGY: {
    color: 'coreBlue',
    content: 'IS',
  },
  LEARNING_ACTIVITY: {
    color: 'coreBlue',
    content: 'LA'
  },
  PRIVATE_TAG: {
    color: 'coreBlue',
    content: 'P'
  },
  READING_SKILLS: {
    color: 'coreBlue',
    content: 'RS'
  },
  CONTENT_SETTINGS_TOPIC: {
    color: 'red',
    content: 'CST'
  }

};

// The Genre enum is used to filter derived standards on assessment questions.
// When creating assessment questions, the default genre is NONFICTION.
export const NONFICTION = 'NONFICTION';

/**
 * Converts an array of strings to an object with
 * the strings as keys and values.
 * ex: ['A', 'B'] => { A: 'A', B: 'B' }
 * Exported for testing.
 * @param {Array} enumArray
 * @returns {Object} constants object
 */
export function enumToConstants (enumArray) {
  return enumArray.reduce((acc, currentValue) => {
    acc[currentValue] = currentValue;
    return acc;
  }, {});
}

export const EVENTS = enumToConstants(rawSchema.enums.EventName);
export const STATUS = enumToConstants(rawSchema.enums.ContentStatus);

export const MAGIC_BUTTON_DESCRIPTIONS = {
  // Note: the description uses nonbreaking spaces to force the tooltip
  // onto a single line.
  COPY_MAX_ENGLISH_LEVEL: 'Copy MAX English level',
  COPY_LEVEL_ABOVE: 'Copy level above',
  COPY_TITLE: 'Copy title'
};

/**
 * Power word filter constants.
 */
export const FILTER_BUTTON_TEXT = 'Filter suggestions by grade level';
export const FILTER_CLEAR_TEXT = 'Clear Filter';
export const FILTER_SUBHEADER = 'Select one or more levels.';
export const CLOSE_LABEL = 'Close filter';

// The keys and values used to populate the filter checkboxes.
export const LEVEL_GRADE_BAND_HASH = {
  2: 'GRADE_2',
  3: 'GRADE_3',
  4: 'GRADE_4',
  5: 'GRADE_5',
  6: 'GRADE_6',
  7: 'GRADE_7',
  8: 'GRADE_8',
  '9 & 10': ['GRADE_9', 'GRADE_10'],
  '11 & 12': ['GRADE_11', 'GRADE_12']
};

export const ENVIRONMENT_FLAGS = {
  local: {
    name: 'LOCAL',
    color: 'blue'
  },
  feature: {
    name: 'FEATURE',
    color: 'purple'
  },
  staging: {
    name: 'STAGING',
    color: 'yellow'
  },
  dev: {
    name: 'DEV',
    color: 'red'
  }
};

export const DEEP_LINK_SUPPORTED_FIELDS = ['attached', 'children', 'headerImage'];

// List of content types, used in inventory management content type filter
export const ALL_CONTENT_TYPES = rawSchema.enums.ContentType;
export const ALL_DISCONTINUED_CONTENT_TYPES = ['AUDIO', 'VIDEO', 'INSTRUCTIONAL_NOTE'];
export const ALL_SUPPORTED_CONTENT_TYPES = ALL_CONTENT_TYPES.filter(
  (contentType) => !ALL_DISCONTINUED_CONTENT_TYPES.includes(contentType)
);

export const ALL_ARTICLE_TYPES = rawSchema.enums.ArticleType;
export const ALL_DISCONTINUED_ARTICLE_TYPES = ['ARTICLE_BIOGRAPHY'];
export const ALL_SUPPORTED_ARTICLE_TYPES = ALL_ARTICLE_TYPES.filter(
  (articleType) => !ALL_DISCONTINUED_ARTICLE_TYPES.includes(articleType)
);
